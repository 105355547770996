var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
export var reformatLegacyLocale = function (localeName) {
  if (!localeName) {
    return null;
  }
  var language = localeName.substring(0, 2);
  var country = localeName.substring(3, 5).toUpperCase();
  return "".concat(language, "_").concat(country);
};
export var toLegacyLocale = function (localeName) {
  return localeName.toLowerCase().replace('_', '-');
};
var LocaleFormat;
(function (LocaleFormat) {
  LocaleFormat["Default"] = "lang-COUNTRY";
  LocaleFormat["LowerCase"] = "lang-country";
  LocaleFormat["Underscore"] = "lang_COUNTRY";
  LocaleFormat["LowerUnderscore"] = "lang_country";
})(LocaleFormat || (LocaleFormat = {}));
function getFormattedLocale(locale, format) {
  var _a;
  if (format === void 0) {
    format = LocaleFormat.Default;
  }
  var matches = locale.match(/^(\w{2})[-_](\w{2})$/);
  var language = 'en';
  var country = 'US';
  if (matches && matches.length === 3) {
    _a = __read(matches, 3), language = _a[1], country = _a[2];
  }
  switch (format) {
    case LocaleFormat.LowerCase:
      return [language.toLowerCase(), country.toLowerCase()].join('-');
    case LocaleFormat.Underscore:
      return [language.toLowerCase(), country.toUpperCase()].join('_');
    case LocaleFormat.LowerUnderscore:
      return [language.toLowerCase(), country.toLowerCase()].join('_');
    case LocaleFormat.Default:
    default:
      return [language.toLowerCase(), country.toUpperCase()].join('-');
  }
}
export { getFormattedLocale, LocaleFormat };