import { LocaleCountriesMap } from './filtered-countries.config';
var thisLocale = null;
var countryList;
function getCountries(locale) {
  var _a;
  var getLocale = locale.toLowerCase().replace('_', '-');
  if (getLocale !== thisLocale) {
    thisLocale = getLocale;
    countryList = new Map(Object.entries((_a = LocaleCountriesMap[getLocale]) !== null && _a !== void 0 ? _a : LocaleCountriesMap['en-us']));
  }
  return countryList;
}
var getLocalizedCountry = function (iso, locale) {
  var countryList = getCountries(locale);
  return countryList.get(iso);
};
export { getCountries, getLocalizedCountry };