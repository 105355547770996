var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { BaseHttpClient } from '../base-http-client';
import { AtlasApi } from './atlas.api';
import { PartnerizeApi } from './partnerize.api';
import { PartnersApi } from './partners.api';
import { PerformanceApi } from './performance.api';
import { UsersApi } from './users.api';
var BffClient = /** @class */function (_super) {
  __extends(BffClient, _super);
  function BffClient(state, logger) {
    var _this = this;
    var _a = state.appConfig.bff,
      hostname = _a.hostname,
      protocol = _a.protocol;
    var baseUrl = "".concat(protocol, "//").concat(hostname);
    _this = _super.call(this, baseUrl, 'bff', logger) || this;
    _this.token = state.auth.token;
    _this.atlas = new AtlasApi(_this);
    _this.partners = new PartnersApi(_this);
    _this.users = new UsersApi(_this);
    _this.performance = new PerformanceApi(_this);
    _this.partnerize = new PartnerizeApi(_this);
    return _this;
  }
  // Override super.request since bff doesn't return normal HTTP errors
  BffClient.prototype.request = function (config, operation) {
    return __awaiter(this, void 0, Promise, function () {
      var startTime, baseLog, response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            startTime = Date.now();
            baseLog = {
              clientName: this.clientName,
              baseUrl: this.baseUrl,
              operation: operation,
              path: config.url,
              data: config.data
            };
            config.headers = __assign(__assign({}, config.headers), {
              Authorization: "Bearer ".concat(this.token)
            });
            this.logger.info(__assign({
              event: 'axios-bff-request-start'
            }, baseLog));
            return [4 /*yield*/, this.axios.request(config)];
          case 1:
            response = _a.sent();
            this.logger.info(__assign(__assign({}, baseLog), {
              success: response.data.success,
              status: response.data.errorCode || response.status,
              error: response.data.errorCode ? response.data.body : undefined,
              event: 'axios-bff-request-end',
              duration: Date.now() - startTime
            }));
            if (response.data.success === false) {
              return [2 /*return*/, Promise.reject(response)];
            }
            return [2 /*return*/, response];
        }
      });
    });
  };
  return BffClient;
}(BaseHttpClient);
export { BffClient };