import { SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';
export var getPrefetchLogger = function (logger) {
  return {
    info: function (msg) {
      return logger.logEvent(new SystemEvent(SystemEventLevel.INFO, 'query-client-prefetch'), {
        log: msg
      });
    },
    error: function (msg) {
      return logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'query-client-prefetch'), {
        log: msg
      });
    },
    warn: function (msg) {
      return logger.logEvent(new SystemEvent(SystemEventLevel.WARN, 'query-client-prefetch'), {
        log: msg
      });
    }
  };
};