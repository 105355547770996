var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { format } from 'date-fns/format';
var PerformanceApi = /** @class */function () {
  function PerformanceApi(client) {
    this.client = client;
    this.path = '/api';
  }
  PerformanceApi.prototype.getPerformanceData = function (partnerId) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        // TODO Migrate performance data calls here. This is going to be a big task.
        console.log('Perf data for', partnerId); // lint escape
        return [2 /*return*/, null];
      });
    });
  };
  PerformanceApi.prototype.getTopPerformingVideos = function (partnerId, queryParams) {
    return __awaiter(this, void 0, Promise, function () {
      var startDate, endDate, currency, maxResultSize, params, data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            startDate = queryParams.startDate, endDate = queryParams.endDate, currency = queryParams.currency, maxResultSize = queryParams.maxResultSize;
            params = new URLSearchParams(__assign(__assign({
              startDate: startDate && format(startDate, 'yyyy-MM-dd'),
              endDate: endDate && format(endDate, 'yyyy-MM-dd')
            }, currency && {
              currency: currency
            }), maxResultSize && {
              maxResultSize: '10 '
            }));
            return [4 /*yield*/, this.client.get({
              url: "".concat(this.path, "/top-performing-videos/").concat(partnerId, "?").concat(params.toString()),
              operation: 'get-top-performing-videos'
            })];
          case 1:
            data = _a.sent().data;
            return [2 /*return*/, data];
        }
      });
    });
  };
  PerformanceApi.prototype.getTopPerformingLinks = function (partnerId, queryParams) {
    return __awaiter(this, void 0, Promise, function () {
      var startDate, endDate, currency, maxResultSize, linkType, params, data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            startDate = queryParams.startDate, endDate = queryParams.endDate, currency = queryParams.currency, maxResultSize = queryParams.maxResultSize, linkType = queryParams.linkType;
            params = new URLSearchParams(__assign(__assign(__assign({
              startDate: startDate && format(startDate, 'yyyy-MM-dd'),
              endDate: endDate && format(endDate, 'yyyy-MM-dd')
            }, currency && {
              currency: currency
            }), maxResultSize && {
              maxResultSize: '10 '
            }), {
              linkType: linkType
            }));
            return [4 /*yield*/, this.client.get({
              url: "".concat(this.path, "/top-performing-links/").concat(partnerId, "?").concat(params.toString()),
              operation: 'get-top-performing-links'
            })];
          case 1:
            data = _a.sent().data;
            return [2 /*return*/, data];
        }
      });
    });
  };
  return PerformanceApi;
}();
export { PerformanceApi };