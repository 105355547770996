var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { BankDetailsController } from 'features/app/account/bank-details/bank-details.controller';
import { BankDetailsCreateController } from 'features/app/account/bank-details/create/create.controller';
import { ProfileController } from 'features/app/account/profile/profile.controller';
import { SettingsController } from 'features/app/account/settings/settings.controller';
import { DashboardController } from 'features/app/dashboard/dashboard.controller';
import { LinkBuilderController } from 'features/app/products/link-builder/link-builder.controller';
import { CallbackController } from 'features/auth/callback/callback.controller';
import { LoginController } from 'features/auth/login/login.controller';
import { LogoutController } from 'features/auth/logout/logout.controller';
import { TravelCreatorController } from 'features/creator/travel-creator/travel-creator.controller';
import { FlexDocumentController } from 'features/flex-document/flex-document.controller';
import { HomeController } from 'features/home/home.controller';
import { TravelVideoController } from 'features/travel-video/travel-video.controller';
import { TravelVideoAcceptanceAgreementController } from 'features/travel-video/video-acceptance-agreement/video-acceptance-agreement.controller';
import { TravelVideoGuidelinesController } from 'features/travel-video/video-guidelines/video-guidelines.controller';
import { UnsubscribeController } from 'features/unsubscribe/unsubscribe.controller';
var authControllers = [LoginController, CallbackController, LogoutController];
var appControllers = [DashboardController, LinkBuilderController, ProfileController, BankDetailsController, BankDetailsCreateController, SettingsController];
var travelVideoControllers = [TravelVideoController, TravelVideoGuidelinesController, TravelVideoAcceptanceAgreementController];
export var controllers = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(authControllers), false), __read(appControllers), false), __read(travelVideoControllers), false), [HomeController, TravelCreatorController, FlexDocumentController, UnsubscribeController], false);